/* src/index.css */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
@import "@fortawesome/fontawesome-free/css/all.min.css";

/* Hide scrollbar for Chrome, Safari, and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.line {
  width: 100px;
  height: 3px;
  background-color: #f5951c;
  margin: 0 auto;
  margin-top: 6px;
  border-radius: 10px;
}
.grayline {
  height: 2px;
  background-color: gray;
  margin: 0 auto;
  margin-top: 6px;
  border-radius: 10px;
}

body {
  font-family: "Urbanist", sans-serif !important;
}
/* src/Components/Spinner.css */
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5em;
}
